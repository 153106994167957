
import { Vue, Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import { OrcamentoObras } from '@/core/models/orcamentoObras';
import { OrcamentoObrasService } from '@/core/services/orcamentoObras';
 
@Component  
export default class ListaTipoComposicao extends mixins(Vue,ListPage) {
  public service =  new OrcamentoObrasService();
  public item = new OrcamentoObras();
  
  loading: boolean = false; 
  dialogCadastro: boolean = false; 
  sheet:boolean = false;

  titulo: string = 'Orçamento';
  subTitulo: string = 'Orçamentos cadastrados no Sistema';

  options: any = {
    itemsPerPage: 15
  };   

  filter = {
    id: 0, 
    descricao: ''
  }

  headers: any[] = [
    { text: '', value: 'actions', sortable: false },
    { text: 'Código', value: 'id' },
    { text: 'Descrição do Orçamento', value: 'descricao' }
  ];

  
  breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Lista de Orçamento de Obras', disabled: true, href: '/financeiro/painel/despesas'}
  ]

  @Watch("options", { deep: true })
  Atualizar() { 
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;
 
    this.service.Listar(page,itemsPerPage, ['id'], sortBy.length == 0 ? [true] : sortDesc,search, columns, this.filter, '', '', '').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },  
      (err) => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        } 
      }).finally(() => (this.loading = false));
  }
 
  Novo(){
    this.item = new OrcamentoObras();
    this.dialogCadastro = true; 
  }  

  Editar(item){
    this.service.ObterPorId(item.id, 'Estado, Etapas.Itens.Composicao,Etapas.Itens.Composicao.Tipo,Etapas.Itens.Composicao.Classe, Etapas.Itens.Composicao.Unidade, Etapas.Itens.Composicao.Itens.Insumo').then(
      (res) => {
        this.item = new OrcamentoObras(res.data);
        this.dialogCadastro = true; 
      },
      (err) => { 
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else if (err.response.status == 403) {
          this.$swal("Aviso", err.response.data.message, "warning" );
        } else {
          this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
        } 
      }
    )
  }

  mounted(){
    
  }
}
